import React from 'react';

import { PropTypes } from 'prop-types';

import Seo from '../components/Seo';

import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import { Container } from '@mui/system';

function Quiz(props) {
    return (
        <Container maxWidth="sm">
            <Seo title="Dezkr PM Certification Quiz" />
            <Typography color="primary" variant="h5" mt={3} align="center">
                Dezkr PM Certification Quiz
            </Typography>
            <Button
                size="large"
                fullWidth
                sx={{ mt: 5 }}
                variant="contained"
                component="a"
                href="https://forms.gle/Gw2yhFLzB7fD9DEDA"
                target="_blank"
            >
                Take the quiz
            </Button>
        </Container>
    );
}

Quiz.propTypes = {};
export default Quiz;
